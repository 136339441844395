/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.filter-hidden {
  display: none;
}
.loadingText {
  margin-bottom: -20px;
  margin-top: 20px;
  width: 249px;
  position: absolute;
  left: calc(50% - 95px);
}
.page-item.active {
  border-radius: 5rem;
}
.hr-light {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.filter-dropdown {
  min-width: 20%;
  max-width: 25%;
  position: absolute;
  left: -15px;
  top: 40px;
  z-index: 9999;
  width: auto;
  background-color: white !important;
}
.dark-layout {
  .filter-dropdown {
    background-color: #283046 !important;
  }
  .flatpickr-year-select {
    background-color: #283046 !important;
  }
  .flatpickr-year-select select {
    background: #283046;
  }
}
.special-link {
  color: #ffd4e4 !important;
  text-shadow: 1px 2px 8px black;
}
.text-highlight {
  text-shadow: 1px 2px 8px white;
}
.recharts-text {
  font-size: x-small;
}
.crawl-extras {
  background-color: rgba(171, 183, 183, 0.3);
  height: 100%;
  transition: visibility 0s, opacity 0.5s linear;
}
.crawl-tags {
  height: 220px;
  overflow: auto;
}
.stats-text {
  color: #222;
  font-size: smaller;
}
.small-cross {
  color: white;
  cursor: pointer;
}

.cross {
  margin-top: -3px;
  color: black;
  cursor: pointer;
}

.cross-red {
  margin-top: -3px;
  color: red;
  cursor: pointer;
}

.cross :hover {
  color: #a808ae !important;
}

.crawl-extras p {
  color: black;
}
.crawl-filters {
  display: inline-flex;
}

.crawl-filters-block:hover {
  color: #ef85ab !important;
  cursor: pointer;
}

.crawl-filters-top-block {
  z-index: 999;
  position: absolute;
  right: 20px;
}
.crawl-arrow {
  margin-top: 4px;
}

.crawl-clear-text:hover {
  color: #ef85ab !important;
  cursor: pointer;
}

.search-username:hover {
  color: #ef85ab !important;
  cursor: pointer;
}

.s-selected {
  color: #ef85ab !important;
}
.crawl-clear-text {
  float: right;
  font-size: smaller;
}

.tag-create {
  position: absolute;
  bottom: 30px;
  width: 80%;
}
.alert-gone {
  position: absolute;
  bottom: 30px;
  width: 80%;
}
.alert-gone span {
  color: red;
  font-size: smaller;
}
.tag-label {
  margin-top: 2px;
  margin-right: 2px;
  font-size: smaller;
}

.clip-icon :hover {
  cursor: pointer;
  color: #a808ae !important;
}

/* */
.flatpickr-current-month .numInputWrapper {
  display: none;
}
.flatpickr-current-month span.cur-month {
  margin-right: 10px;
  width: 45px;
  font-size: 0.75em;
  top: 0px;
  padding-top: 10px;
  padding-right: 2px;
  position: absolute;
  left: 0px;
  font-weight: 600;
  text-align: right;
}
.flatpickr-current-month {
  width: 100px;
}
.flatpickr-year-select {
  background-color: #ffffff;
  z-index: 100;
  position: absolute;
  top: 7.5px;
  right: 35px;
  width: 100px;
}
.flatpickr-year-select select {
  width: 100%;
  height: 24px;
  //font-weight: 600;
  outline: 0;
  overflow: hidden;
  background: #ffffff;
  color: #747a80;
  // border: #ffffff;
  padding: 0;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 6px;
}

/* Haloween Spider */
.spider {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 30%;
  z-index: 99;
  /* 4. Animatie */
  animation: swing 2s infinite;
  transform-origin: top;

  /* Bonus */
  transition: 0.8s ease-in-out;
}

.spider .spiderweb {
  width: 2px;
  height: 200px;
  margin-left: 49px;
  background: rgba(255, 255, 255, 0.7);
}

.spider .body {
  width: 100px;
  height: 80px;
  background: #222;
  position: relative;
  border-radius: 50%;
}

.spider .body .eye {
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: 20px;
  background: #fff;
  border-radius: 50%;
}

.spider .body .eye.left {
  left: 20px;
}

.spider .body .eye.right {
  right: 20px;
}

.spider .body .eye:after {
  background: #222;
  width: 7px;
  height: 7px;
  content: '';
  display: block;
  margin: 55%;
  border-radius: 50%;

  /* 3. Animatie */
  animation: look 4s infinite;
}

.spider .legs .leg {
  width: 80px;
  height: 40px;
  margin-top: -20px;
  border: 5px solid transparent;
  border-top-color: #333;
  border-radius: 40px 40px 0 0;
}

.spider .legs {
  position: absolute;
  bottom: -10%;
  z-index: -1;
}

.spider .legs.left {
  left: -70%;
}
.spider .legs.right {
  right: -60%;
}

.legs.left .leg:nth-child(1) {
  transform: rotate(10deg);
  margin-left: 10px;
}
.legs.right .leg:nth-child(1) {
  transform: rotate(-10deg);
  margin-left: -10px;
}

.legs.left .leg:nth-child(2) {
  transform: rotate(-20deg);
  margin-left: 20px;
}
.legs.right .leg:nth-child(2) {
  transform: rotate(20deg);
  margin-left: -20px;
}

.legs.left .leg:nth-child(3) {
  transform: rotate(-50deg);
  margin-left: 30px;
}
.legs.right .leg:nth-child(3) {
  transform: rotate(50deg);
  margin-left: -30px;
}

/* 1. Animatie */
@keyframes look {
  0%,
  40%,
  100% {
    transform: translateX(0);
  }

  45%,
  95% {
    transform: translateX(-110%);
  }
}

/* 3. Animatie */
@keyframes swing {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

.swal2-actions {
  justify-content: space-evenly !important;
}

.inline-buttons {
  margin-right: 5px;
}
.delete-button-product {
  position: relative;
  left: 2px;
}
.spacer {
  margin-bottom: 20px;
}

.instagram-story {
  display: flex; /* Use flexbox to control the layout */
  flex-direction: column; /* Stack children vertically */
  width: 300px; /* Fixed width */
  height: 720px; /* Fixed height, adjust as needed */
  margin: 15px; /* Spacing around each card */
  overflow: hidden; /* Hide overflow */
}

.card-img-top {
  //width: 100%; /* Full width */
  object-fit: cover; /* Cover the area without stretching */
  flex-grow: 0; /* Don’t grow taller than the content */
  flex-shrink: 0; /* Don’t shrink */
  height: 100%; /* Adjust the height as needed */
  max-height: 390px;
}

.story-footer {
  margin-top: auto; /* Pushes the footer to the bottom */
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  color: white; /* Light text for visibility */
}

.story-info {
  padding: 0.5em; /* Padding for text */
  text-align: center; /* Center the text */
}

.story-info h5,
.story-info p {
  margin: 0; /* Remove margins */
}

.story-actions {
  display: flex;
  justify-content: space-around; /* Evenly space around the buttons */
  width: 100%; /* Full width */
}

.story-actions button {
  //flex: 1; /* Distribute space evenly to buttons */
  font-size: x-small;
  width: 80px;
}

/* Grid Layout for the InstagramStory Components */
.stories-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -15px; /* Negative margin to offset individual card margins */
}

@media (min-width: 768px) {
  .instagram-story {
    max-width: 350px;
  }
}

.button-group-prd {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust the space between buttons */
}

@media (max-width: 768px) {
  /* Adjust this based on your needs */
  .button-group-prd {
    flex-direction: column;
  }

  .button-group-prd button {
    width: 100%;
  }
}
.media-card {
  width: 204px;
  object-fit: cover; /* Ensures the image covers the space without distorting */
  margin-left: 20px;
  margin-right: 5px;

  margin-bottom: 10px;
  overflow: hidden; /* Ensures content does not exceed card boundaries */
  position: relative; /* Needed for absolute positioning of child elements */
}

.media-card-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.media-card-image-yt {
  object-fit: cover;
  width: 100%;
  /* height: 100%; */
  /* position: absolute; */
  top: 0;
  margin: auto;
}

.video-fluid {
  height: 100%;
  width: inherit;
}

.font-small-2 {
  display: flex; /* Ensures that the contents are laid out in a row. */
  flex-wrap: nowrap; /* Prevents the flex items from wrapping to a new line. */
  max-width: 100%; /* Adjust this value based on the parent's size to ensure it fits as intended. */
  overflow: hidden; /* Ensures that any overflowing content is hidden. */
}

.no-wrap-place {
  white-space: nowrap; /* Ensures that the text does not wrap onto a new line. */
  overflow: hidden; /* Ensures that any overflowing text is hidden. */
  text-overflow: ellipsis; /* Adds an ellipsis to the end of the overflowing text. */
  max-width: 100%; /* Limits the width of the span to ensure that overflow is handled correctly. */
  margin-left: 4px; /* Adds a small space before the place if needed. Adjust as necessary. */
}

.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5em;
}

.result-title {
  margin-bottom: 1rem;
}

.result-item {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 1rem;
}

.result-body {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.social-icon {
  /* Add size or other styles for the social icon */
}

.result-link {
  /* Styles for the result link */
}

.result-status {
  /* Styles for the result status, e.g., color coding */
  font-weight: bold;
}

.result-reason {
  /* Styles for the reason, if you want it to appear below the result */
  font-style: italic;
}

.dropzone-component {
  .dropzone {
    border: 2px dashed #007bff;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    background-color: #f8f9fa;
    cursor: pointer;
    transition: border 0.24s ease-in-out, background-color 0.24s ease-in-out;

    &:hover {
      background-color: #e2e6ea;
    }
  }

  .dropzone-instruction {
    font-size: 16px;
    color: #6c757d;
  }

  .file-list {
    list-style: none;
    padding: 0;
    margin-top: 20px;
  }
  .file-item {
    display: flex; /* Make it a flex container */
    justify-content: space-between; /* Space between items */
    align-items: center; /* Align items vertically */
    margin: 10px 0;
    font-size: 14px;
    color: #495057;
  }

  .file-icon {
    margin-right: 10px; /* Keep for spacing */
    vertical-align: middle;
  }
  .trash-icon:hover {
    cursor: pointer; /* Only changes cursor for the trash icon */
  }
  /* Optional: If you find the file path and first icon need to be more tightly grouped */
  .file-info {
    display: flex; /* Makes the file info a flex container */
    align-items: center; /* Aligns the icon and text vertically */
  }
}

.new-file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.new-file-info {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.new-file-link {
  margin-right: auto; /* Ensures link takes all available space, pushing everything else to the right */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-actions {
  display: flex;
  align-items: center;
}

.new-file-date {
  margin-right: 10px; /* Space between date and trash icon */
}

.new-trash-icon {
  cursor: pointer;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 200px; /* Adjust based on your needs */
}

.left-border {
  border-left: 0.5px solid #ccc;
}

.custom-radio-group .custom-control {
  display: flex;
  align-items: center;
}

.custom-radio-group .custom-control-label {
  margin-left: 0.5rem; /* Adjust this value if needed */
}

.profile-media-filter-select {
  width: 220px;
}

.basic-numerical-loader {
  position: absolute;
  left: 0;
  top: 4px;
}

.is-warning {
  border-color: orange;
  //background-color: #fff3cd;
}
.text-warning {
  color: orange;
}
